import React from "react"
import Link from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgCover from "../../assets/images/mission/scientific-objectives/cover.png"

const LaunchVehiclePage = () => (
  <Layout lang="ar">
    <SEO title="Scientific Objectives" lang="ar" />
    <main>
            <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 40">
                <img src={imgCover} style={{transform: "translate(50%,-50%)"}}  alt="" data-uk-cover />
                <div class="uk-overlay uk-position-cover header-overlay"></div>
            </div>
            <section id="about-emm" style={{ marginTop: "-100px", position: "relative" }}>
                <div className="large-container pb6">
                    <div className="standard-container standard-bg" style={{ paddingBottom: "0px" }}>
                        <div className="mb5 relative">
                            <h2 className="relative">
                            اﻟﺠﺎﻧﺐ اﻟﻌﻠﻤﻲ
                    <div className="text-underline-heavy"></div>
                            </h2>

                            <h3>التطور التاریخي</h3>
                            <p>
ﺗﺸﯿﺮ اﻟﺼﻮر اﻟﺘﻲ ﺗﻢ اﻟﺘﻘﺎﻃﻬﺎ ﻟﺴﻄﺢ ﻛﻮﻛﺐ اﻟﻤﺮﯾﺦ إﻟﻰ وﺟﻮد أدﻟﺔ ﻋﻠﻰ أن اﻟﻤﺮخ ﻛﺎن رﻃﺒﺎً وأﻛﺜﺮ دﻓﺌﺎً ﻣﻤﺎ ﻫﻮ ﻋﻠﯿﻪ اﻟﯿﻮم وﯾُﻌﺪ اﻟﺘﻐﯿﺮ اﻟﻤﻨﺎﺧﻲ وﻓﻘﺪان اﻟﻐﻼف اﻟﺠﻮي ﻫﻲ أﻫﻢ اﻷﺳﺒﺎب اﻟﺘﻲ أدت إﻟﻰ ﺗﺤﻮل اﻟﻤﺮﯾﺦ إﻟﻰ ﻛﻮﻛﺐ ﺟﺎف وﻣﻐﺒﺮ. وﻓﻲ اﻟﻮﻗﺖ اﻟﺬي ﯾﻮاﺻﻞ ﻓﯿﻪ اﻟﻌﻠﻤﺎء ﻣﻦ ﺟﻤﯿﻊ أﻧﺤﺎء اﻟﻌﺎﻟﻢ دراﺳﺔ اﻟﺘﻄﻮر اﻟﺘﺎرﯾﺦ ﻟﻄﻘﺲ ﻛﻮﻛﺐ اﻟﻤﺮﯾﺦ، ﯾﺄﺗﻲ ﻣﺸﺮوع اﻹﻣﺎرات ﻻﺳﺘﻜﺸﺎف اﻟﻤﺮﯾﺦ ﺑﺄوﻟﻮﯾﺔ ﺗﻮﻓﯿﺮ ﺑﯿﺎﻧﺎت ﻋﻠﻤﯿﺔ ﻣﻦ ﺷﺄﻧﻬﺎ أن ﺗﺴﺪ اﻟﻔﺠﻮة اﻟﻤﻌﺮﻓﯿﺔ ﻟﺪﯾﻨﺎ ﻋﻦ ﻓﻬﻤﻨﺎ ﻟﻄﺒﯿﻌﺔ ﻣﻨﺎخ اﻟﻤﺮﯾﺦ ﻓﻲ اﻟﻮﻗﺖ اﻟﺤﺎﻟﻲ.

                            </p>

                            <h3>القمر الاصطناعي الأول لمراقبة طقس المریخ</h3>
                            <p>
                            
ﯾُﻌﺪ ﻣﺴﺒﺎر اﻷﻣﻞ ﻫﻮ اﻷول ﻣﻦ ﻧﻮﻋﻪ ﻓﯿﻤﺎ ﯾﺘﻌﻠﻖ ﺑﺮﺻﺪ اﻟﺘﻐﯿﺮات اﻟﻤﻨﺎﺧﯿﺔ ﻋﻠﻰ اﻟﻤﺮﯾﺦ، ﺣﯿﺚ ﺳﯿﻘﻮم ﺑﺪراﺳﺔ ﻧﻈﺎم اﻟﻄﻘﺲ ﻋﻠﻰ اﻟﻜﻮﻛﺐ اﻷﺣﻤﺮ ﺑﺸﻜﻞ ﻛﺎﻣﻞ، ﻣﻦ ﺧﻼل رﺻﺪ اﻟﺘﻐﯿﺮات اﻟﻤﻨﺎﺧﯿﺔ ﻓﻲ اﻟﻐﻼف اﻟﺠﻮي اﻟﺴﻔﻠﻲ ﻋﻠﻰ ﻣﺪار اﻟﯿﻮم ﻷول ﻣﺮة،وﻓﻲ ﻛﺎﻓﺔ أﻧﺤﺎء اﻟﻜﻮﻛﺐ وﻋﺒﺮ ﻣﺨﺘﻠﻒ اﻟﻔﺼﻮل واﻟﻤﻮاﺳﻢ. وﺗﺰاﻣﻨﺎً ﻣﻊ ﻫﺬا ﺳﯿﻘﻮم اﻟﻤﺴﺒﺎر ﺑﻤﺮاﻗﺒﺔ اﻧﺘﺸﺎر ﻏﺎزي اﻟﻬﯿﺪروﺟﯿﻦ .واﻷﻛﺴﺠﯿﻦ ﻓﻲ اﻟﻄﺒﻘﺔ اﻟﻌﻠﯿﺎ ﻣﻦ اﻟﻐﻼف اﻟﺠﻮي ﻟﻠﻤﺮﯾﺦ
ﻛﻤﺎ ﺳﯿﺮﻛﺰ ﻣﺴﺒﺎر اﻷﻣﻞ ﻋﻠﻰ ﻛﺸﻒ اﻟﻌﻼﻗﺔ ﺑﯿﻦ اﻟﺘﻐﯿﺮات اﻟﻤﻨﺎﺧﯿﺔ ﻓﻲ اﻟﻄﺒﻘﺔ اﻟﺴﻔﻠﻰ ﻣﻦ اﻟﻐﻼف اﻟﺠﻮي ﻟﻠﻤﺮﯾﺦ، وﻓﻘﺪان ﻏﺎزي اﻟﻬﯿﺪروﺟﯿﻦ واﻷﻛﺴﺠﯿﻦ ﻣﻦ اﻟﻄﺒﻘﺔ اﻟﻌﻠﯿﺎ ﻟﻐﻼﻓﻪ اﻟﺠﻮي. وﺗُﻌﺪ ﻫﺬه ﻫﻲ اﻟﻤﺮة اﻷوﻟﻰ اﻟﺘﻲ ﻧﺘﻤﻜﻦ ﻓﯿﻬﺎ ﻧﺤﻦ اﻟﺒﺸﺮ ﻣﻦ دراﺳﺔ اﻟﻌﻼﻗﺔ ﺑﯿﻦ اﻟﺘﻐﯿﺮات اﻟﻤﻨﺎﺧﯿﺔ وﻓﻘﺪان اﻟﻐﻼف اﻟﺠﻮي ﻟﻠﻜﻮﻛﺐ اﻷﺣﻤﺮ، وﻫﻲ اﻟﻌﻤﻠﯿﺔ اﻟﺘﻲ رﺑﻤﺎ ﺗﻜﻮن ﻣﺴﺆوﻟﺔ ﻋﻦ ﺗﺤﻮل اﻟﻤﺮﯾﺦ، ﻋﻠﻰ ﻣﺪى ﻣﻠﯿﺎرات اﻟﺴﻨﯿﻦ، ﻣﻦ ﻛﻮﻛﺐ ﯾﻤﺘﻠﻚ ﻏﻼﻓﺎً ﺟﻮﯾﺎً ﺳﻤﯿﻜﺎً ﻗﺎدراً ﻋﻠﻰ اﻟﺤﻔﺎظ ﻋﻠﻰ اﻟﻤﺎء ﻓﻲ ﺣﺎﻟﺘﻪ اﻟﺴﺎﺋﻠﺔ .ﻋﻠﻰ اﻟﺴﻄﺢ، إﻟﻰ ﻫﺬا اﻟﻜﻮﻛﺐ اﻟﻘﺎﺣﻞ واﻟﺒﺎرد اﻟﺬي ﯾﻤﻠﺘﻚ ﻏﻼﻓﺎً ﺟﻮﯾﺎً رﻗﯿﻘﺎً ﻛﻤﺎ ﻧﺮاه اﻟﯿﻮم.
                            
                            </p>

                            <h3>الأجهزة العلمیة لمسبار الأمل</h3>
                            <p>
                            سيقوم مسبار الأمل بمهمته التي تتعلق بدارسة الغلاف الجوي للمريخ من مدار علمي يكون في أقرب نقطة إلى سطح المريخ على ارتفاع يبلغ 20 ألف كيلومتر وفي أبعد نقطة يكون على ارتفاع 43 ألف كيلومتر، وسيتمكن المسبار من إتمام دورة كاملة حول الكوكب خلال 55 ساعة بدرجة ميل مداري تبلغ 25 درجة. ويُعطى هذا المدار أفضلية لمسبار الأمل عن أي مركبة فضائية أخرى، حيث لم يكن لأي من المهمات السابقة إلى المريخ مداراً مشابهاً؛ حيث كانت لها مدارات لا تسمح لها سوى بدراسة الغلاف الجوي للمريخ في وقت واحد خلال اليوم. ويحمل مسبار الأمل على متنه ثلاثة أجهزة علمية تعمل معاً بتناغم كامل وفي وقت واحد لمراقبة المكونات الرئيسية للغلاف الجوي للمريخ.
                            </p>

                            <h3>ﻛﺎﻣﯿﺮا اﻻﺳﺘﻜﺸﺎف اﻟﺮﻗﻤﯿﺔ (EXI)</h3>
                            <p>
                            كاميرا إشعاعية متعددة الطول الموجي، قادرة على التقاط صور مرئية للمريخ بدقة 12 ميجا بكسل. ولديها القدرة أيضاً على كشف توزيع جليد الماء والأوزون في الطبقة السفلى من الغلاف الجوي للمريخ باستخدام حزم الأشعة فوق البنفسجية.
                            </p>

                            <h3>ﻣﻘﯿﺎس ﻃﯿﻔﻲ ﺑﺎﻷﺷﻌﺔ ﺗﺤﺖ اﻟﺤﻤﺮاء (EMIRS)</h3>
                            <p>
                                
                            ﯾﺮﺻﺪ ﻫﺬا اﻟﻤﺴﺒﺎر اﻟﻤﺮﯾﺦ ﻣﻦ ﺧﻼل ﺣﺰم اﻷﺷﻌﺔ ﺗﺤﺖ اﻟﺤﻤﺮاء، ﻋﺒﺮ ﻗﯿﺎس اﻟﻌﻤﻖ اﻟﺒﺼﺮي ﻟﻠﻐﺒﺎر واﻟﺴﺤﺐ اﻟﺠﻠﯿﺪﯾﺔ وﺑﺨﺎر اﻟﻤﺎء ﻓﻲ اﻟﻐﻼف اﻟﺠﻮي. ﻛﻤﺎ ﯾﻘﻮم أﯾﻀﺎً ﺑﻘﯿﺎس درﺟﺔ ﺣﺮارة اﻟﺴﻄﺢ ودرﺟﺔ اﻟﺤﺮارة ﻓﻲ اﻟﻐﻼف اﻟﺠﻮي اﻟﺴﻔﻠﻲ.

                            </p>

                            <h3>
                            مقياس طيفي بالأشعة فوق البنفسجية (EMUS)
                            </h3>

                            <p>
ﯾﻘﻮم ﺑﺪراﺳﺔ اﻟﻄﺒﻘﺔ اﻟﻌﻠﻮﯾﺔ ﻣﻦ اﻟﻐﻼف اﻟﺠﻮي ﻟﻠﻤﺮﯾﺦ، ﻣﻦ ﺧﻼل ﺣﺰم اﻷﺷﻌﺔ ﻓﻮق اﻟﺒﻨﻔﺴﺠﯿﺔ ﻃﻮﯾﻠﺔ اﻟﻤﺪى. وﻫﻮ ﻗﺎدر ﻋﻠﻰ ﺗﺤﺪﯾﺪ ﺗﻮزﯾﻊ أول أﻛﺴﯿﺪ اﻟﻜﺮﺑﻮن واﻷﻛﺴﺠﯿﻦ ﻓﻲ اﻟﻐﻼف اﻟﺤﺮاري ﻟﻠﻜﻮﻛﺐ اﻷﺣﻤﺮ. ﻛﻤﺎ ﯾﻘﯿﺲ ﻛﻤﯿﺔ اﻟﻬﯿﺪروﺟﯿﻦ واﻷﻛﺴﺠﯿﻦ ﻓﻲ اﻟﻐﻼف اﻟﺨﺎرﺟﻲ ﻟﻠﻤﺮﯾﺦ. 
                            </p>

                        </div>
                        <div>ﻟﻤﻌﺮﻓﺔ اﻟﻤﺰﯾﺪ ﺣﻮل <a href="/ar/hope-probe/instruments"> اﻷﺟﻬﺰة اﻟﻌﻠﻤﯿﺔ ﻟﻤﺴﺒﺎر اﻷﻣﻞ</a></div>
                    </div>

                </div>
            </section>
        </main>
    </Layout>
)

export default LaunchVehiclePage





// import React from "react"

// import Layout from "../../components/layout"
// import SEO from "../../components/seo"
// import imgCover from "../../assets/images/mission/scientific-objectives/cover.png"
// import imgHope from "../../assets/images/mission/scientific-objectives/hope-probe.png"
// import imgRocket from "../../assets/images/hope-probe/launch-vehicle/rocket.png"

// const LaunchVehiclePage = () => (
//   <Layout lang="ar">
//     <SEO title="Scientific Objectives" lang="ar" />
//     <main>
//       <div className="uk-cover-container" data-uk-height-viewport="offset-top: true; offset-bottom: 60">
//         {/* <canvas width="100%" height="100%"></canvas> */}
//         <img src={imgCover} alt="" uk-cover />
//         <div class="uk-overlay uk-position-cover header-overlay"></div>
//       </div>
//       <section id="about-emm" style={{marginTop: "-100px", position: "relative"}}>
//           <div className="large-container pb6">
//             <div className="standard-container standard-bg" style={{paddingBottom: "0px"}}>
//                 <div className="mb5 relative">
//                   <h2 className="relative">
//                   الأهداف العلمية
//                     <div className="text-underline-heavy"></div>
//                   </h2>
//                   <p className="mt5">
//                   ما يزال فهمنا للغلاف الجوي للمريخ محدوداً بشكل كبير، وذلك بسبب ثبات التوقيت المحلي خلال القياسات الأخيرة التي أجرتها مركبات فضائية عدة تاركة أغلب دورات المريخ النهارية غير مستكشفة في معظم أنحاء الكوكب. وبالتالي فإنه لا تتوفر لدينا أية معلومات مهمة حول الكيفية التي تؤثر بها تفاعلات الغلاف الجوي على الدورة النهارية للكوكب. ولم نتمكن من فهم كيفية انتقال الطاقة من طبقات الغلاف الجوي السفلى والوسطى إلى الطبقة العليا منه وذلك بسبب هذه التغطية المحدودة للبعثات السابقة. لذلك قمنا بتصميم “مسبار الأمل” ليكون قادراً على مراقبة واستكشاف طبقات الغلاف الجوي السفلى والعليا في وقت متزامن، وذلك من خلال دورانه حول الكوكب في مدار مرتفع بحيث يتمكن من دراسة الكوكب من منظور شامل. وبسبب زاوية الرؤية التي تتيح له دراسة معظم سطح المريخ على مدار فترات اليوم، سيتمكن المسبار من إجراء دراسات غير مسبوقة للعمليات الفيزيائية التي تتحكم في دوران الغلاف الجوي ومراقبة أنماط درجة الحرارة وتجمعات السحب الجليدية وبخار الماء والغبار والتفاعلات التي تتم بينها (يمكن لهذه التفاعلات أن تحجب السطح أثناء العواصف المذهلة التي تحدث على المريخ).
//                   </p>
//                   <div className="flex flex-column flex-row-ns">
//                       <div className="w-100 w-50-ns">
//                         <p>
//                         وإضافة إلى ذلك، سيتمكن مشروع الإمارات لاستكشاف المريخ “مسبار الأمل” من الكشف عن العلاقة بين هذه الظروف المختلفة التي تحدث في الطبقة السفلى من الغلاف الجوي وظاهرة هروب الهيدروجين والأكسجين من الطبقة العليا للغلاف الجوي، وهو الأمر الذي يُعتقد أنه مسؤول عن تحول كوكب المريخ -على مدار مليارات السنين- من كوكب ذو غلاف جوي سميك قادر على الحفاظ على الماء في حالته السائلة على السطح إلى الغلاف الجوي الرقيق والبارد والجاف الذي نراه اليوم. يوضح الجدول رقم (1) الأمور التي ينفرد بها مشروع الإمارات لاستكشاف المريخ “مسبار الأمل” عن غيره من المهمات السابقة من حيث مساحة التغطية والموضوعات العلمية ذات الصلة.
//                         </p>
//                         <h3 className="dn db-ns">
//                         الأهداف والأسئلة العلمية لمشروع الإمارات لاستكشاف المريخ
//                         </h3>
//                       </div>
//                       <div className="w-100 w-50-ns">
//                           <img src={imgHope}  alt=""/>
//                       </div>
//                   </div>

//                 </div>
//                 <div className="mt4-ns mv3">
//                     <h3 className="db dn-ns">
//                     الأهداف والأسئلة العلمية لمشروع الإمارات لاستكشاف المريخ
//                     </h3>
//                 </div>
                
//             </div>
//             <div class="uk-grid uk-grid-small uk-child-width-expand@s uk-text-center" data-uk-grid>
//                 <div>
//                     <div className="animated-card animated-card-text-trigger">
//                         <div className="flex flex-column justify-center uk-card-secondary"  style={{minHeight: "250px"}}>
//                             <div className="pa2">
//                                 <p className="uk-text-bold text-light mb0 pb0">
//                                 كيف تستجيب الطبقة السفلى من الغلاف الجوي للمريخ للإشعاع الشمسي، على مدار اليوم، جغرافياً، وموسمياً؟
//                                 </p>
//                             </div>
//                             <div className="ph2 animated-card-text relative">
//                                 <div className="absolute left-1" style={{fontSize: "6rem", lineHeight: "6rem", color: "#0a0a0a", fontWeight: "800", opacity: "0.5"}}>
//                                     A
//                                 </div>
//                                 <p className="text-light uk-text-left uk-text-small pl5">
//                                 الهدف الأول: توصيف حالة الطبقة السفلى من الغلاف الجوي للمريخ على نطاق عالمي وعلى مدار فترات اليوم وفي مختلف الأماكن والمواسم.
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div>
//                     <div className="animated-card animated-card-text-trigger">
//                         <div className="flex flex-column justify-center uk-card-secondary"  style={{minHeight: "250px"}}>
//                             <div className="pa2">
//                                 <p className="uk-text-bold text-light mb0 pb0">
//                                 كيف تؤثر الظروف المناخية للغلاف الجوي للمريخ على معدلات الانبعاثات الصادرة منه؟
//                                 </p>
//                             </div>
//                             <div className="ph2 animated-card-text relative">
//                                 <div className="absolute left-1" style={{fontSize: "6rem", lineHeight: "6rem", color: "#0a0a0a", fontWeight: "800", opacity: "0.5"}}>
//                                     B
//                                 </div>
//                                 <p className="text-light uk-text-left uk-text-small pl5">
//                                 الهدف الثاني: الربط بين معدلات الانبعاث الحراري والكيميائي ومدى قدرة الغلاف الجوي للمريخ على اعتراض هذه الانبعاثات.
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div>
//                     <div className="animated-card animated-card-text-trigger">
//                         <div className="flex flex-column justify-center uk-card-secondary"  style={{minHeight: "250px"}}>
//                             <div className="pa2">
//                                 <p className="uk-text-bold text-light mb0 pb0">
//                                 كيف تتفاعل مكونات الطبقة العليا من الغلاف الجوي للمريخ باختلاف الوقت والمكان؟
//                                 </p>
//                             </div>
//                             <div className="ph2 animated-card-text relative">
//                                 <div className="absolute left-1" style={{fontSize: "6rem", color: "#0a0a0a", fontWeight: "800", lineHeight: "6rem", opacity: "0.5"}}>
//                                     C
//                                 </div>
//                                 <p className="text-light uk-text-left uk-text-small pl5">
//                                 توصيف تنوع بنية الغلاف الجوي باختلاف المكان ومدى تنوع المكونات الرئيسية للطبقة العليا من الغلاف الجوي للمريخ.
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="standard-container standard-bg">
//                 <h3>
//                 سيقوم فريق مشروع الإمارات لاستكشاف المريخ - مسبار الأمل بالتالي:
//                 </h3>
//                 <uk className="uk-list-disc">
//                     <li>
//                     التوحد مع المجتمع العلمي العالمي المختص في علوم المريخ لإيجاد إجابات للأسئلة الرئيسية التي لم يسبق لأية مهمة أخرى الإجابة عليها.
//                     </li>
//                     <li>
//                     دراسة أسباب تلاشي الطبقة العليا من الغلاف الجوي للمريخ من خلال تتبع الأسباب الكامنة وراء ظاهرة هروب غازي الهيدروجين والأكسجين المكونان الرئيسيان للماء.
//                     </li>
//                     <li>
//                     دراسة العلاقة بين الطبقات السفلى والعليا من الغلاف الجوي للمريخ.
//                     </li>
//                     <li>
//                     تكوين أول صورة شاملة حول تقلبات الغلاف الجوي خلال النهار وبين الفصول.
//                     </li>
//                     <li>
//                     تتبع الظواهر الجوية مثل العواصف الترابية وتقلبات درجة الحرارة وكيفية تفاعل الغلاف الجوي مع التضاريس.
//                     </li>
//                     <li>
//                     الكشف عن أسباب تأكسد سطح المريخ.
//                     </li>
//                     <li>
//                     البحث عن الروابط بين الطقس الحالي للمريخ والمناخ القديم للكوكب الأحمر.
//                     </li>
//                 </uk>
//             </div>
//           </div>  
//       </section>
//     </main>
//   </Layout>
// )

// export default LaunchVehiclePage
